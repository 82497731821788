$(document).ready(function(){
    // TRIGGER MENU
    $('#trigger').click(function(){
        if($(this).hasClass('open')){
            $(this).removeClass('open');
            $('nav ul.menu').slideUp();
        }else{
            $(this).addClass('open');
            $('nav ul.menu').slideDown();

            $('nav ul.menu li > a').click(function(){
                if(!$(this).parent().hasClass('menu-item-has-children')){
                    $('nav ul.menu').slideUp();
                    $('#trigger').removeClass('open');
                }
            });
        }
    });

    //BXSLIDER
    $('.bxslider').bxSlider({
        mode: 'fade',
        speed: '2500',
        pause: '8000',
        auto: true,
        controls: true,
        pager: false,
    });


    //GALLERIA
    if($('#galleria')[0]){
        Galleria.run('#galleria');
    }


    //PGWSLIDER
    $('.pgwSlider').pgwSlider({
        autoSlide: false,
        adaptiveHeight: true,
    });


    //AREAMANAGER
    $('ul.vertegenwoordigergrid li').show();
	$('#postcodeareamanager input.send').click(function() {
		var filter = $('#postcodeareamanager input.postcode').val();
        if (filter.length > 0){
			$('ul.vertegenwoordigergrid li').each(function(){
				if ($(this).hasClass(filter) && filter.length > 0){
                    var tonen = true;
                }else{
                    var tonen = false;
                }

				if (tonen === true){
					$(this).show();
				} else {
					$(this).hide();
				}
			});
		} else {
			$('ul.vertegenwoordigergrid li').show();
		}
	});
    $('#postcodeareamanager input.postcode').on('keyup', function (e) {
        if (e.keyCode == 13) {
            e.preventDefault();
        	var filter = $('#postcodeareamanager input.postcode').val();
            if (filter.length > 0){
        		$('ul.vertegenwoordigergrid li').each(function(){
        			if ($(this).hasClass(filter) && filter.length > 0){
        	          var tonen = true;
        	        }else{
        	          var tonen = false;
        	        }

        			if (tonen === true){
        				$(this).show();
        			} else {
        				$(this).hide();
        			}
        		});
        	} else {
        		$('ul.vertegenwoordigergrid li').show();
        	}
        }
    });


    // PRODUCTBESCHRIJVING SMOOTH SCROLL
    $(function() {
      $('a.productbeschrijving[href*="#"]:not([href="#"])').click(function() {
        if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
          var target = $(this.hash);
          target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
          if (target.length) {
            $('html, body').animate({
              scrollTop: target.offset().top - 100
            }, 1000);
            return false;
          }
        }
      });
    });
});


equalheight = function(container){
var currentTallest = 0,
     currentRowStart = 0,
     rowDivs = new Array(),
     $el,
     topPosition = 0;
 $(container).each(function() {
   $el = $(this);
   $($el).height('auto')
   topPostion = $el.position().top;

   if (currentRowStart != topPostion) {
     for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
       rowDivs[currentDiv].height(currentTallest);
     }
     rowDivs.length = 0; // empty the array
     currentRowStart = topPostion;
     currentTallest = $el.height();
     rowDivs.push($el);
   } else {
     rowDivs.push($el);
     currentTallest = (currentTallest < $el.height()) ? ($el.height()) : (currentTallest);
  }
   for (currentDiv = 0 ; currentDiv < rowDivs.length ; currentDiv++) {
     rowDivs[currentDiv].height(currentTallest);
   }
 });
}
$(window).load(function() {
  equalheight('ul.imagegrid li');
});
$(window).resize(function(){
  equalheight('ul.imagegrid li');
});
